import React, {FC, useEffect, useState} from 'react';
import {connect, ConnectedProps} from "react-redux";
import {
    DialogTitle,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogContentText
} from "@material-ui/core";

import {RootState} from "../store/reducers";
import {SAVE_GIFT_TITLE} from "../store/actions";

const mapStateToProps = (state: RootState) => ({
    needEditDetails: state.app.needEditDetails,
    giftTitle: state.app.giftTitle
});

const mapDispatchToProps = (dispatch: any) => ({
    actionSaveGift: (giftTitle: string | null, needSave: boolean) =>
        dispatch({type: SAVE_GIFT_TITLE, giftTitle, needSave}),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type DetailsDialogProps = ConnectedProps<typeof connector> & {};

const DetailsDialog: FC<DetailsDialogProps> = (props: DetailsDialogProps) => {
    const [giftTitle, setGiftTitle] = useState(props.giftTitle);


    const handleSave = () => {
        props.actionSaveGift(
            giftTitle,
            true);
    };

    const handleCancel = () => {
        props.actionSaveGift(
            '',
            false);
    };

    useEffect(() => {
        setGiftTitle(props.giftTitle);
    }, [props.giftTitle, props.needEditDetails]);

    return (
        <Dialog open={props.needEditDetails} onClose={handleCancel} aria-labelledby="form-gift-details-dialog">
            <DialogTitle id="form-gift-details-dialog">Virtual Gift Title</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter title for your Virtual Gift:
                </DialogContentText>
                <TextField autoFocus
                           margin="dense"
                           id="form-gift-details-dialog-gift-title"
                           label="Gift title"
                           type="text"
                           fullWidth
                           multiline
                           rows={3}
                           rowsMax={3}
                           value={giftTitle}
                           onChange={(event) =>
                               setGiftTitle(event.target.value)}
                />


            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connector(DetailsDialog);
