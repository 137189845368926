import {requests} from "../api";
import log from "../log";

export const OPEN_ENVELOPE = 'OPEN_ENVELOPE';
export const CLOSE_ENVELOPE = 'CLOSE_ENVELOPE';
export const EDIT_LETTER = 'EDIT_LETTER';
export const FAILED_FETCH_GIFT = 'FAILED_FETCH_GIFT';
export const GIFT_FETCHED = 'GIFT_FETCHED';
export const GIFT_IS_FETCHING = 'GIFT_IS_FETCHING';
export const SAVE_GIFT_CONTENT = 'SAVE_GIFT_CONTENT';
export const SAVE_GIFT_TITLE = 'SAVE_GIFT_TITLE';
export const GIFT_IS_SAVING = 'GIFT_IS_SAVING';
export const GIFT_WAS_SAVED = 'GIFT_WAS_SAVED';
export const GIFT_WAS_MODIFIED = 'GIFT_WAS_MODIFIED';
export const FAILED_SAVE_GIFT = 'FAILED_SAVE_GIFT';
export const CREATE_GIFT = 'CREATE_GIFT';
export const CREATE_GIFT_ERROR = 'CREATE_GIFT_ERROR';
export const GO_HOME = 'GO_HOME';
export const EDIT_DETAILS = 'EDIT_DETAILS';
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT';
export const PUBLISH_GIFT = 'PUBLISH_GIFT';
export const PUBLISH_GIFT_CANCEL = 'PUBLISH_GIFT_CANCEL';
export const PUBLISH_GIFT_START = 'PUBLISH_GIFT_START';


// used as example too
export const openEnvelopeAction = () => {
    return async (dispatch: any, getState: any) => {
        return dispatch({type: OPEN_ENVELOPE})
    }
};

export const giftWasModifiedAction = () =>
     async (dispatch: any, getState: any) => {
        return dispatch({type: OPEN_ENVELOPE})
};

export const closeEnvelopeAction = () => ({type: CLOSE_ENVELOPE});

export const createGift = () => {
    return async (dispatch: any, getState: any) => {
        try {
            const data = await requests.post('gift/create');
            const giftId = data.body.giftId;
            log.debug(`Created gift ${giftId}`);
            const redirectTo = `/${giftId}/`;
            return dispatch({type: CREATE_GIFT, redirectTo});
        } catch (e) {
            log.error(`Failed to create gift with error: ${e}`);
            return dispatch({type: CREATE_GIFT_ERROR, error: e});
        }
    };
};

export const publishGift = () => {
    return async (dispatch: any, getState: any) => {
        dispatch({type: PUBLISH_GIFT_START});
        dispatch({type: SAVE_GIFT_CONTENT});
        /* setTimeout(async () => {
             try {
                 const giftId = getState().fetchedGiftId;
                 if(!giftId) {
                     return dispatch({type: PUBLISH_GIFT_FAILED,
                         error: `There is no current gift to publish`});
                 }

                 const data = await requests.put(`/gift/${giftId}/publish/`,
                     {});

                 log.debug(`Published gift ${giftId}`);
                 const redirectTo = `/${giftId}/`;
                 return dispatch({type: CREATE_GIFT, redirectTo});
             } catch (e) {
                 log.error(`Failed to create gift with error: ${e}`);
                 return dispatch({type: CREATE_GIFT, redirectTo: ''});
             }

             dispatch({type: PUBLISH_GIFT_SUCCEED});
         }, 400);*/
        /*try {
            const data = await requests.post('gift/create');
            const giftId = data.body.giftId;
            log.debug(`Created gift ${giftId}`);
            const redirectTo = `/${giftId}/`;
            return dispatch({type: CREATE_GIFT, redirectTo});
        } catch (e) {
            log.error(`Failed to create gift with error: ${e}`);
            return dispatch({type: CREATE_GIFT, redirectTo: ''});
        }*/
    };
};
/*

export const giftFetched = (giftId: string) => ({
    type: GIFT_FETCHED,
    fetchedGiftId: giftId,
    giftFetchingErrors: '',
    isGiftFetching: false
});

export const failedToFetchGift = (giftId: string, error: string) => ({
    type: FAILED_FETCH_GIFT,
    fetchedGiftId: giftId,
    giftFetchingErrors: error,
    isGiftFetching: false
});

export const giftIsFetching = (giftId: string) => ({
    type: GIFT_IS_FETCHING,
    fetchedGiftId: giftId,
    giftFetchingErrors: '',
    isGiftFetching: true
});
*/
