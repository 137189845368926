import React, {FC, useState} from 'react';
import {connect, ConnectedProps} from "react-redux";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit'
import DetailsIcon from '@material-ui/icons/Details'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {Backdrop, Box, Button, Tooltip} from "@material-ui/core";
import {SpeedDial as MSpeedDial} from "@material-ui/lab";

import {RootState} from "../store/reducers";
import {EDIT_LETTER, SAVE_GIFT_CONTENT, GO_HOME, EDIT_DETAILS, PUBLISH_GIFT} from "../store/actions";
import {isShareAvailable, isStaticPage, navigatorShare} from "../utils";
import log from "../log";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        speedDial: {
            position: 'absolute',
            // bottom: theme.spacing(8),
            // right: theme.spacing(3),
            bottom: theme.spacing(3),
            right: theme.spacing(3),
        },
        margin: {
            margin: theme.spacing(2),
        },
        buttons: {
            position: 'relative',
            // width: 340,
            top: theme.spacing(-3),
            flex: '0 0 auto'
        }
    }),
);

const mapStateToProps = (state: RootState) => ({
    giftWasSaved: state.app.giftWasSaved,
    needEditLetter: state.app.needEditLetter,
    isStaticPage: isStaticPage(state.router.location.pathname),
    giftPublished: state.app.giftPublished,
    giftTitle: state.app.giftTitle,
    fetchedGiftId: state.app.fetchedGiftId,
    giftFetchingError: state.app.giftFetchingError
});

const mapDispatchToProps = (dispatch: any) => ({
    actionEditLetter: () =>
        dispatch({type: EDIT_LETTER}),
    actionSaveGift: () =>
        dispatch({type: SAVE_GIFT_CONTENT}),
    actionGoHome: () =>
        dispatch({type: GO_HOME}),
    actionEditDetails: () =>
        dispatch({type: EDIT_DETAILS}),
    actionPublishGift: () =>
        dispatch({type: PUBLISH_GIFT}),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type SpeedDialProps = ConnectedProps<typeof connector> & {};

const AppBar: FC<SpeedDialProps> = (props: SpeedDialProps) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [openCopyClipboardTooltip, setOpenCopyClipboardTooltip] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const shareData = {
        title: 'Gift for you!',
        text: `${props.giftTitle}`,
        url: `https://vgift.us/${props.fetchedGiftId}/`,
    };


    const shareThis = () => {
        navigatorShare(shareData);
        setOpen(false);
    };

    const copyToClipboard = async () => {
        const text = `${shareData.title}\n${shareData.url}`;
        log.debug('To copy: ', text);
        await navigator.clipboard.writeText(text);
        setOpenCopyClipboardTooltip(true);
    };

    const renderEditMenu = () => {
        return (
            <div className="buttons-container">

                <div className="buttons-bar">
                    <Backdrop open={open}/>
                    <MSpeedDial
                        ariaLabel="SpeedDial tooltip example"
                        className={classes.speedDial}
                        hidden={props.isStaticPage}
                        icon={<SpeedDialIcon/>}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        open={open}
                    >
                        {!props.needEditLetter &&
                        <SpeedDialAction key="Edit" tooltipTitle="Edit" icon={<EditIcon/>} tooltipOpen
                                         onClick={() => {
                                             handleClose();
                                             props.actionEditLetter();
                                         }}
                        />}

                        {!props.giftWasSaved &&
                        <SpeedDialAction key="Save" tooltipTitle="Save" icon={<SaveIcon/>} tooltipOpen
                                         onClick={() => {
                                             handleClose();
                                             props.actionSaveGift();
                                         }}/>}
                        <SpeedDialAction key="Title" tooltipTitle="Title" icon={<DetailsIcon/>} tooltipOpen
                                         onClick={() => {
                                             handleClose();
                                             props.actionEditDetails();
                                         }}/>
                        <SpeedDialAction key="Publish" tooltipTitle="Publish" icon={<ShareIcon/>} tooltipOpen
                                         onClick={() => {
                                             handleClose();
                                             props.actionPublishGift();
                                         }}/>
                        <SpeedDialAction key="Create" tooltipTitle="Create" icon={<AddIcon/>} tooltipOpen
                                         onClick={() => {
                                             handleClose();
                                             props.actionGoHome();
                                         }}/>
                    </MSpeedDial>
                </div>
            </div>
        )
    };

    const renderPublishedMenu = () => {
        return (
            <Box p={1} display="flex" justifyContent="center">
                <Box p={1} className={classes.buttons}>
                    {isShareAvailable() &&
                    <Button
                        size="small"
                        className={classes.margin}
                        startIcon={<ShareIcon/>}
                        onClick={shareThis}
                    >
                        Share
                    </Button>}
                    <Tooltip title="Gift has been copied to clipboard"
                             leaveDelay={2000}
                             placement="top"
                             onClose={() => {
                                 setOpenCopyClipboardTooltip(false);
                             }}
                             open={openCopyClipboardTooltip}
                    >
                        <Button
                            size="small"
                            className={classes.margin}
                            startIcon={<FileCopyIcon/>}
                            onClick={copyToClipboard}
                        >
                            Copy
                        </Button>
                    </Tooltip>
                    <Tooltip title="Create new virtual gift"
                             leaveDelay={400}
                             placement="top"
                    >
                        <Button
                            size="small"
                            className={classes.margin}
                            startIcon={<AddIcon/>}
                            onClick={() => {
                                props.actionGoHome();
                            }}
                        >
                            Create
                        </Button>
                    </Tooltip>
                </Box>
            </Box>
        )
    };

    return (
        // <div className={props.isHomePage ? "hidden" : "buttons-container"}>
        <div>
            {!props.giftFetchingError && (props.giftPublished ? renderPublishedMenu() : renderEditMenu())}
        </div>
    );
    /*
{icon: <EditIcon/>, name: 'Edit', handler: props.actionEditLetter},
{icon: <SaveIcon/>, name: 'Save', handler: props.actionSaveGift},
{icon: <DetailsIcon/>, name: 'Details', handler: props.actionEditDetails},
{icon: <ShareIcon/>, name: 'Publish', handler: props.actionPublishGift},
{icon: <AddIcon/>, name: 'Create', handler: props.actionGoHome},*/
};

export default connector(AppBar);

/*
icon={<EditIcon/>}
openIcon={<SpeedDialIcon />}*/
