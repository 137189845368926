import superagent from 'superagent';
import urljoin from 'url-join';
import log from './log';
import {API_ROOT} from "./conf";

const absurl = (suburl: string) => {
    const res = urljoin(API_ROOT, suburl);
    log.info(`absurl = ${res}`);
    return res;
};

export const requests = {
    get: (url: string) =>
        superagent.get(absurl(url)),

    put: (url: string, body: any) =>
        superagent.put(absurl(url), body),

    post: (url: string, body: any = null) =>
        superagent.post(absurl(url), body)
};
