// @ts-ignore
// tslint:disable-next-line:no-var-requires
const alloyeditor = require("alloyeditor");
const AlloyEditor = alloyeditor.AlloyEditor;

const SelectionTest = AlloyEditor.SelectionTest;
const SelectionSetPosition = AlloyEditor.SelectionSetPosition;
const SelectionGetArrowBoxClasses = AlloyEditor.SelectionGetArrowBoxClasses;

const EditorSelections = [
    {
        name: 'embed',
        buttons: ['embedRemove', 'embedEdit'],
        test: SelectionTest.embed,
    },
    {
        name: 'embedUrl',
        buttons: ['embedVideo', 'embedVideoEdit'],
        test: SelectionTest.embedUrl,
    },
    {
        name: 'link',
        buttons: ['linkEdit'],
        test: SelectionTest.link,
    },
    {
        name: 'image',
        buttons: ['imageLeft', 'imageCenter', 'imageRight', 'removeImage'],
        setPosition: SelectionSetPosition.image,
        test: SelectionTest.image,
    },
    {
        name: 'text',
        buttons: {
            full: [
                [
                    'Font',
                    'FontSize',
                    'separator',
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'separator',
                    'link',
                ],
                [
                    'paragraphAlign',
                    'separator',
                    'ul',
                    'ol',
                    'separator',
                    'h1',
                    'h2',
                    'separator',
                    'indentBlock',
                    'outdentBlock',
                    'separator',
                    'TextColor',
                    'BGColor',
                    'separator',
                    'code',
                    'quote',
                    'separator',
                    'removeFormat',
                ],
            ],

            simple:[
                [
                    'styles',
                    'bold',
                    'italic',
                    'underline',
                    'link',
                ],
                [
                    'paragraphAlign',
                    'separator',
                    'indentBlock',
                    'outdentBlock',
                    'separator',
                    'TextColor',
                    'BGColor',
                    'separator',
                    'removeFormat',
                ]
            ],
        },
        test: SelectionTest.text,
    },
    {
        name: 'table',
        buttons: [
            'tableHeading',
            'tableRow',
            'tableColumn',
            'tableCell',
            'tableRemove',
        ],
        getArrowBoxClasses: SelectionGetArrowBoxClasses.table,
        setPosition: SelectionSetPosition.table,
        test: SelectionTest.table,
    },
];


const AE_EMBED_URL_TPL = 'https://iframely.vgift.us/oembed?url={url}&callback={callback}';

const EDITOR_TOOLBARS = {
    add: {
        buttons: [
            'imageFromFile',
            'embed',
            'camera',
            'hline',
            'smiley',
            'table',
        ],
        tabIndex: 2,
    },
    styles: {
        selections: EditorSelections,
        tabIndex: 1
    }
};

const EDITOR_CONFIG = {
    toolbars: EDITOR_TOOLBARS,
    embedUrlTemplate: AE_EMBED_URL_TPL,
    spritemap: '/alloy-editor/assets/icons/icons.svg',
    // mode: 'full',
/*    fitImageMaxWidth: 280,
    fitImageMaxHeight: 350,*/
};

export {AlloyEditor, EDITOR_CONFIG};
