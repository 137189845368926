
export const IS_DEBUG = process.env.DEBUG === 'true' || process.env.NODE_ENV === 'development';

export const STATIC_PAGES = [
    '/',
    '/status/'
];

export const VERSION = '1.2.0';

export const API_ROOT = process.env.REACT_APP_VGIFT_API_ROOT || '';

// export const API_ROOT = 'http://192.168.17.100:3010/api';
// const API_ROOT = 'http://vgift.us/api';
// const API_ROOT = 'http://localhost:3010/api';

