import React, {FC, MouseEvent, useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {useCookies} from 'react-cookie';

import {RootState} from "../store/reducers";
import Icon from "@mdi/react";
import {mdiDotsHorizontal} from "@mdi/js";
import {getNavigatorLanguage} from "../utils";

// tslint:disable-next-line:no-var-requires
const blankFlag = require("../images/flag-blank.png");

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type TranslatorProps = ConnectedProps<typeof connector> & {
};

const Translator: FC<TranslatorProps> = (props: TranslatorProps) => {
    const [selectorVisible, setSelectorVisible] = useState(false);
    const [cookies, setCookie] = useCookies(['chosenLanguage']);

    const translator = (lang: string) => (event: MouseEvent) => {
        translate(lang);
        event.preventDefault();
        return false;
    };

    const translate = (lang: string) => {
        // tslint:disable-next-line:no-eval
        eval(`doGTranslate("en|${lang}")`);
        setCookie('chosenLanguage', lang);
    };

    useEffect(() => {
        let lang = cookies.chosenLanguage;
        if (!cookies.chosenLanguage) {
            lang = getNavigatorLanguage();
        }

        if (lang !== 'en') {
            translate(lang);
        }
    }, [cookies.chosenLanguage, translate]);

    const flags = [
        {lang: 'en', title: 'English', xOffset: 0, yOffset: 0},
        {lang: 'zh-CN', title: 'Chinese (Simplified)', xOffset: -300, yOffset: 0},
        {lang: 'fr', title: 'French', xOffset: -200, yOffset: -100},
        {lang: 'de', title: 'German', xOffset: -300, yOffset: -100},
        {lang: 'it', title: 'Italian', xOffset: -600, yOffset: -100},
        {lang: 'ja', title: 'Japanese', xOffset: -700, yOffset: -100},
        {lang: 'ko', title: 'Korean', xOffset: 0, yOffset: -200},
        {lang: 'es', title: 'Spanish', xOffset: -600, yOffset: -200},
        {lang: 'ru', title: 'Russian', xOffset: -500, yOffset: -200},
    ];

    const renderFlag = ({lang, title, xOffset, yOffset}: typeof flags[0]) => {
        return (
            <a href="#"
               key={lang}
               className="gflag nturl"
               onClick={translator(lang)}
               title={title}
               style={{backgroundPosition: `${xOffset}px ${yOffset}px`}}>
                <img src={blankFlag} height="32" width="32" alt={title}/>
            </a>
        )
    };

    return (
        <div className="language-container">
            <div className="language-selector">
                <div className="language-selector-flags">
                    {flags.map((flag) => renderFlag(flag))}

                    <a onClick={(event: MouseEvent) => {
                        setSelectorVisible(!selectorVisible);
                        event.preventDefault();
                    }} href="#" key={"more"}>
                        <Icon path={mdiDotsHorizontal}
                              size={'32px'}
                              horizontal
                              vertical
                              rotate={180}
                        />
                    </a>

                </div>
                <div className="language-selector-select" style={{
                    display: selectorVisible ? "flex" : "none"
                }}>
                    <select onChange={(event) => {
                        translate(event.target.value);
                    }}>
                        <option value="">Select Language</option>
                        <option value="af">Afrikaans</option>
                        <option value="sq">Albanian</option>
                        <option value="ar">Arabic</option>
                        <option value="hy">Armenian</option>
                        <option value="az">Azerbaijani</option>
                        <option value="eu">Basque</option>
                        <option value="be">Belarusian</option>
                        <option value="bg">Bulgarian</option>
                        <option value="ca">Catalan</option>
                        <option value="zh-CN">Chinese (Simplified)</option>
                        <option value="zh-TW">Chinese (Traditional)</option>
                        <option value="hr">Croatian</option>
                        <option value="cs">Czech</option>
                        <option value="da">Danish</option>
                        <option value="nl">Dutch</option>
                        <option value="en">English</option>
                        <option value="et">Estonian</option>
                        <option value="tl">Filipino</option>
                        <option value="fi">Finnish</option>
                        <option value="fr">French</option>
                        <option value="gl">Galician</option>
                        <option value="ka">Georgian</option>
                        <option value="de">German</option>
                        <option value="el">Greek</option>
                        <option value="ht">Haitian Creole</option>
                        <option value="iw">Hebrew</option>
                        <option value="hi">Hindi</option>
                        <option value="hu">Hungarian</option>
                        <option value="is">Icelandic</option>
                        <option value="id">Indonesian</option>
                        <option value="ga">Irish</option>
                        <option value="it">Italian</option>
                        <option value="ja">Japanese</option>
                        <option value="ko">Korean</option>
                        <option value="lv">Latvian</option>
                        <option value="lt">Lithuanian</option>
                        <option value="mk">Macedonian</option>
                        <option value="ms">Malay</option>
                        <option value="mt">Maltese</option>
                        <option value="no">Norwegian</option>
                        <option value="fa">Persian</option>
                        <option value="pl">Polish</option>
                        <option value="pt">Portuguese</option>
                        <option value="ro">Romanian</option>
                        <option value="ru">Russian</option>
                        <option value="sr">Serbian</option>
                        <option value="sk">Slovak</option>
                        <option value="sl">Slovenian</option>
                        <option value="es">Spanish</option>
                        <option value="sw">Swahili</option>
                        <option value="sv">Swedish</option>
                        <option value="th">Thai</option>
                        <option value="tr">Turkish</option>
                        <option value="uk">Ukrainian</option>
                        <option value="ur">Urdu</option>
                        <option value="vi">Vietnamese</option>
                        <option value="cy">Welsh</option>
                        <option value="yi">Yiddish</option>
                        <option value="eo">Esperanto</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default connector(Translator);
