import {RootState} from "../store/reducers";
import {connect, ConnectedProps} from "react-redux";
import React, {FC, useEffect} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Box, Button, Paper} from "@material-ui/core";
// tslint:disable-next-line:no-var-requires
import ReactPlayer from 'react-player';
import {createGift} from "../store/actions";
import {useSnackbar} from "notistack";

// tslint:disable-next-line:no-var-requires
const paperBackground = require("../images/white-paperboard.png");
// tslint:disable-next-line:no-var-requires
const androidGift = require("../images/android-gift.png");

// tslint:disable-next-line:no-var-requires
const introVideo = require("../video/intro.webm");
const introVideoSize = {
    width: 320,
    height: 550,
    //
    // width: 290,
    // height: 500,
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        container: {
            maxWidth: 800,
        },
        textBox: {
            // maxWidth: 700,
            minWidth: 300,
        },
        paper: {
            backgroundColor: '#f6f5db',
            // backgroundColor: '#f2f2f2',
            backgroundImage: `url(${paperBackground})`,
            padding: theme.spacing(2),
            textAlign: 'left',
            // color: theme.palette.text.primary,
            color: '#656567',
        },
        video: {
            minHeight: 250,
        },
        createButton: {
            fontWeight: 'bold',
            position: 'fixed',
            width: 250,
            // height: 20,
            margin: '5% auto',
            left: 0,
            right: 0,
            bottom: '5%',
            // backgroundColor: '#e7290f',
            backgroundColor: '#52ac48',
            '&:hover': {
                // backgroundColor: '#b3200c',
                backgroundColor: '#3d8036',
            },
        }
    }),
);

const mapStateToProps = (state: RootState) => ({
    state: state.app,
    router: state.router
});

const mapDispatchToProps = (dispatch: any) => ({
    actionCreateGift: () =>
        dispatch(createGift()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type HomeProps = ConnectedProps<typeof connector> & {};

const Home: FC<HomeProps> = (props: HomeProps) => {
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (props.state.giftCreationError) {
            const text: string = String(props.state.giftCreationError);
            enqueueSnackbar(text, {
                variant: 'error',
                vertical: 'bottom',
                horizontal: 'center',
            } as any);
        }
    }, [props.state.giftCreationError, enqueueSnackbar]);

    return (
        <div className="home-page">
            <Box display="flex" justifyContent="center">
                <Box display="flex" flexWrap="wrap" justifyContent="center" p={1} m={1} className={classes.container}>
                    <Box p={1} flexBasis={300} justifyContent="center">
                        <ReactPlayer url={introVideo}
                                     playing={true}
                                     loop={true}
                                     muted={true}
                                     width={introVideoSize.width}
                                     height={introVideoSize.height}/>
                    </Box>
                    <Box p={1} flexBasis={300} flexGrow={1} className={classes.textBox}>
                        <Paper className={classes.paper} square={true} elevation={5}>
                            <h1>How to create a gift online</h1>
                            <p>Do you want to present something online? Envelope it with Virtual Gift:</p>
                            <ol>
                                <li>Create a new gift using a green button below.</li>
                                <li>Write your congratulations and stylize them.</li>
                                <li>Upload your photo or use one of
                                    these <a href="https://unsplash.com/s/photos/cats" target="_blank">cute
                                        kitten's pictures</a>.
                                </li>
                                <li>You can also embed a video from Youtube or similar service.</li>
                                <li>A good idea is to include as a gift something valuable,
                                    e.g. digital certificate link, game's key in the Steam,
                                    new account's credentials, etc.
                                </li>
                                <li>You can provide a Title on the front of the envelope if you want.
                                </li>
                                <li>Publish the gift when it is ready, so nobody can modify it later.
                                </li>
                                <li>Present your gift with an unique link to it.
                                </li>
                            </ol>
                        </Paper>
                    </Box>
                    <Box p={1} flexBasis={300} flexGrow={1} className={classes.textBox}>
                        <Paper className={classes.paper} square={true} elevation={5}>
                            <h2>Features</h2>
                            <ul>
                                <li>Created gifts easy accessible from any device, including mobile
                                </li>
                                <li>Gifts stored under unique unguessable links, so can not be picked up by bad guys.
                                    Only you and those with whom you shared the link will have access to the gift you
                                    created.
                                </li>
                                <li>After publishing gifts can not be modified by anyone, including you</li>
                            </ul>
                            <h2>Coming soon</h2>
                            <ul>
                                <li>More envelope designs.</li>
                                <li>More envelope opening effects.</li>
                                <li>More various letter templates.</li>
                                <li>More editor features.</li>
                                <li>And even more…</li>

                            </ul>
                        </Paper>
                    </Box>
                    <Box p={1} flexBasis={300} justifyContent="center">
                        <img src={androidGift}
                             width={introVideoSize.width}
                             />
                    </Box>
                    <Box p={1} flexBasis={300} flexGrow={1} className={classes.textBox}>
                        <Paper className={classes.paper} square={true} elevation={5}>
                            <h3>Contact us</h3>

                            <p>If you have faced any difficulties, or you have a feature request or proposition
                                for us will be happy to hear from you.
                                Write to us: <a href="mailto:info@vgift.us">info@vgift.us</a>
                            </p>
                        </Paper>
                    </Box>
                    <Box p={1} flexBasis={300} flexGrow={1} className={classes.textBox}>
                        <Paper className={classes.paper} square={true} elevation={5}>
                            <h3>Credits</h3>
                            <p>Many thanks
                                to <a href="https://pixabay.com/ru/users/azotha-8728135/" target="_blank">Azotha</a> for
                                sharing a cool envelope!</p>
                            <p>Thanks for icons made by authors from Flaticon:</p>
                            <ul className="credits">
                                <li> <a href="https://www.flaticon.com/authors/smashicons"
                                        target="_blank" title="Smashicons">Smashicons</a></li>
                                <li><a href="https://www.flaticon.com/authors/vitaly-gorbachev"
                                       target="_blank" title="Vitaly Gorbachev">Vitaly Gorbachev</a></li>
                                <li><a href="https://www.flaticon.com/authors/freepik"
                                       target="_blank" title="Freepik">Freepik</a></li>
                            </ul>
                            <p>Thanks to <a href="https://unsplash.com/photos/Meeycb5dpXs" target="_blank">Sofiya Levchenko</a> from
                                Unsplash for photo for sample gift.</p>
                        </Paper>
                    </Box>
                </Box>
            </Box>
            <Button variant="contained" size="large" color="secondary"
                    className={classes.createButton}
                    onClick={props.actionCreateGift}>
                Create Virtual Gift
            </Button>

        </div>
    );
};

export default connector(Home);
