import {connect, ConnectedProps} from "react-redux";
import React, {FC, useEffect, useState} from "react";
import {Box, Button, Paper, TextField, Tooltip} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ShareIcon from "@material-ui/icons/Share";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {isShareAvailable, navigatorShare} from '../utils';
import {RootState} from "../store/reducers";
import {VERSION} from "../conf";
import {requests} from "../api";
import {DEFAULT_LETTER_CONTENT} from "../templateContent";
import log from "../log";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: {
            maxWidth: 800,
            padding: theme.spacing(3)
        },
    })
);

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type StatusPageProps = ConnectedProps<typeof connector> & {};

const StatusPage: FC<StatusPageProps> = (props: StatusPageProps) => {
    const [openCopyClipboardTooltip, setOpenCopyClipboardTooltip] = useState(false);
    const [serverPing, setServerPing] = useState('');

    const styles = useStyles();
    const testShareData = {
        title: 'Share title',
        text: `Share text`,
        url: `http://example.org/test-url/`,
    };

    const testShareHandler = () => {
        navigatorShare(testShareData);
    };

    const testCopyClipboard = async () => {
        const text = `${testShareData.text}\n${testShareData.url}`;
        await navigator.clipboard.writeText(text);
        setOpenCopyClipboardTooltip(true);
    };

    useEffect(() => {
        const pingServer = async () => {
            try{
                const data = await requests.get('/ping/');
                const answer = data.text;
                setServerPing(answer);
                log.debug('Server responded to ping with: ', String(data));
            }
            catch (e) {
                setServerPing(String(e));
                log.error('Server failed to respond to ping with error: ', e);
            }
        };
        pingServer();
    });

    return (
        <Box display="flex" justifyContent="center">
            <Box>
                <Paper className={styles.page}>
                    <h1>Status Virtual Gift</h1>
                    <p>Version: {VERSION}</p>

                    <p>navigator.share {isShareAvailable() ? "available" : "unavailable"} <br/>
                        <Button
                            startIcon={<ShareIcon/>}
                            onClick={testShareHandler}
                        >
                            Test Share
                        </Button></p>

                    <p>
                        <Tooltip title="Content copied to clipboard" leaveDelay={1000}
                                onClose={() => {
                                    setOpenCopyClipboardTooltip(false);
                                }}
                                open={openCopyClipboardTooltip}
                            >
                            <Button
                                startIcon={<FileCopyIcon/>}
                                onClick={testCopyClipboard}
                                data-for="custom-event"
                                data-tip="custom show"
                                data-event="click focus"
                            >
                                Test Copy
                            </Button>
                        </Tooltip>
                    </p>
                    <p>
                        <TextField autoFocus
                                   margin="dense"
                                   type="text"
                                   fullWidth
                                   multiline
                                   rows={4}
                                   rowsMax={4}
                        />
                    </p>
                    <p>
                        Server answer to ping: {serverPing}
                    </p>
                </Paper>
            </Box>
        </Box>
    )
};

export default connector(StatusPage);
