import React, {FC, useEffect} from 'react';
import {Prompt, Route, Switch} from 'react-router'
import {ConnectedRouter, push} from "connected-react-router";

import {History} from "../store/configureStore";
import GiftBox from "./GiftBox";
import Home from "./Home";
import {RootState} from "../store/reducers";
import {connect, ConnectedProps} from "react-redux";
import Progress from "./Progress";
import AppBar from "./AppBar";

import '../style/app.scss';
import {CLEAR_REDIRECT} from "../store/actions";
import StatusPage from "./StatusPage";
import Translator from "./Translator";

const mapStateToProps = (state : RootState) => ({
    redirectTo: state.app.redirectTo,
    location: state.router.location,
    isGiftFetching: state.app.isGiftFetching,
    isGiftSaving: state.app.isGiftSaving,
    giftWasSaved: state.app.giftWasSaved,
});

const mapDispatchToProps = (dispatch: any) => ({
    redirect: (url:string) => {
        dispatch(push(url));
        dispatch({type: CLEAR_REDIRECT});
    }

});

const connector = connect(mapStateToProps, mapDispatchToProps);

type AppProps = ConnectedProps<typeof connector> & {
    history: History;
};

const App: FC<AppProps> = ({redirectTo, history, redirect, giftWasSaved, isGiftFetching, isGiftSaving}: AppProps) => {
    // const history = props.history;

    useEffect(() => {
        if(redirectTo) {
            redirect(redirectTo);
        }
    }, [redirectTo, redirect]);

    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/status/" component={StatusPage}/>
                <Route exact path="/:id/" component={GiftBox}/>
            </Switch>
            <AppBar />
            <Prompt
                when={!giftWasSaved}
                message={"Your gift is unsaved now. \nAre you sure you want to leave?"}
            />
            <Progress show={isGiftFetching || isGiftSaving}/>
            <Translator />
        </ConnectedRouter>
    );
};

export default connector(App);
