
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LETTER_CONTENT_LONG = `
    <h2>This is a Live Demo</h2>
    <h3>Click/Tap here to edit</h3>
    <p>Cupcake ipsum dolor sit amet halvah. Icing I love carrot cake cotton candy danish brownie wafer
    I love. Cake chocolate cake biscuit tiramisu ice cream pudding pastry. Gingerbread muffin candy canes tart
    tootsie roll.</p>
    <p>​​​​​​​
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFEAAAAeCAYAAABUgfKPAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAACgElEQVRo3u2az2vUQBTH50+y/gIRPHn2UBVbldYf4Enw6NWDguDFtlgPFZGqXVEE8VBE8UdF8BeiiIgWd2Wp66qlu2vd3WaSzPvKSxGTbNqdpOlurPOFt4dkZzL5ZN6bNz+EEEJUmnSo0qQfMNJWw0YJwAHmJ+oSg/k5avRPSKwfsrDurLF2xpz25SRm6/QFwICoNqm867I0cBJY/4SUipAXikA9BkjiHskS/GOAJDcD0UD8RyDGUdMGyvOER3mF45N25EgfVrvGxdVSZd+WlXbmsf+ahCJotzVViGExzHDDuwWRNfTEaQtw84iFzxVatu6OQmSdfuhkBqJ0gZ1tUrnxV27bulctJvIX3HFJYvKDCjz4dUmlBjFunIrS+++EDcPRZQ5etyPduGMQ/9jW0eAL1BYoUxBZo09b3XrLOQszNdLq5R0Znf1yFDIB0d/DuE17rgbdOvfGbXH9VYuJSV4gCxAvvgxSmZ4lbBpZ/O+RmzbCffDkfWdtQtQdVKLKbhy28O5bENXYc9cLP1/ng9fvfFSx2vrfQORrPPA17KCLPysGB8JilTywBuIyzzlxz1myPMfBPl+szAzErAws/nt3p1UkxFMPkuW0qUPcPiZDKU72IG47b3nT06g42FWInGz3jks8LgS/MgfzrEFkO3zjb2Ltj4MdgRhXZ6b0XERn6pYmRLYLL9yWOJg5iFMF/QWIbkDktOfoLXvFnpAaRFctTvEKc+TNn4/dttETo2HdgJhWHWZR1qxsG4hrC6IiKLNlusIt058WSruvmM37JLY3J38pwifhKAzM1KjKa2zmGIl+D+TTD7UFFAEMeudxAPTWJcrmmJK+bNcD2Mf8fgNkcZEz0kEM0wAAAABJRU5ErkJggg=="
            className="" />
    </p>
    <p>Muffin cotton candy cotton candy wafer lollipop cheesecake bear claw. Pastry caramels toffee cake dragée
    toffee chocolate cake cupcake. Pastry lemon drops I love wafer macaroon bonbon marzipan.</p>
    <p>Muffin soufflé jelly beans macaroon I love I love. Cheesecake brownie sugar plum. Cookie donut carrot cake
    gummies. Biscuit jelly sweet dessert sesame snaps tiramisu I love ice cream.</p>
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LETTER_CONTENT_SHORT = `
    <h2>This is a Live Demo</h2>
    <p>Cupcake ipsum dolor sit amet halvah. Icing I love carrot cake cotton candy danish brownie wafer
    </p>
    <p>​​​​​​​
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFEAAAAeCAYAAABUgfKPAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAACgElEQVRo3u2az2vUQBTH50+y/gIRPHn2UBVbldYf4Enw6NWDguDFtlgPFZGqXVEE8VBE8UdF8BeiiIgWd2Wp66qlu2vd3WaSzPvKSxGTbNqdpOlurPOFt4dkZzL5ZN6bNz+EEEJUmnSo0qQfMNJWw0YJwAHmJ+oSg/k5avRPSKwfsrDurLF2xpz25SRm6/QFwICoNqm867I0cBJY/4SUipAXikA9BkjiHskS/GOAJDcD0UD8RyDGUdMGyvOER3mF45N25EgfVrvGxdVSZd+WlXbmsf+ahCJotzVViGExzHDDuwWRNfTEaQtw84iFzxVatu6OQmSdfuhkBqJ0gZ1tUrnxV27bulctJvIX3HFJYvKDCjz4dUmlBjFunIrS+++EDcPRZQ5etyPduGMQ/9jW0eAL1BYoUxBZo09b3XrLOQszNdLq5R0Znf1yFDIB0d/DuE17rgbdOvfGbXH9VYuJSV4gCxAvvgxSmZ4lbBpZ/O+RmzbCffDkfWdtQtQdVKLKbhy28O5bENXYc9cLP1/ng9fvfFSx2vrfQORrPPA17KCLPysGB8JilTywBuIyzzlxz1myPMfBPl+szAzErAws/nt3p1UkxFMPkuW0qUPcPiZDKU72IG47b3nT06g42FWInGz3jks8LgS/MgfzrEFkO3zjb2Ltj4MdgRhXZ6b0XERn6pYmRLYLL9yWOJg5iFMF/QWIbkDktOfoLXvFnpAaRFctTvEKc+TNn4/dttETo2HdgJhWHWZR1qxsG4hrC6IiKLNlusIt058WSruvmM37JLY3J38pwifhKAzM1KjKa2zmGIl+D+TTD7UFFAEMeudxAPTWJcrmmJK+bNcD2Mf8fgNkcZEz0kEM0wAAAABJRU5ErkJggg=="
            className="" />
    </p>
`;

const EXAMPLE_CONGRATS_ALICE = `<p>Modify text, images, videos, smiles with different styling here...</p>
    <h1>Dear Alice</h1>
    <p>May all your wishes come true! Happy Birthday!&nbsp;<img src="/alloy-editor/plugins/smiley/images/angel_smile.png" title="angel" alt="angel" width="23" height="23"></p>
    <p style="text-align: right;">Tom&nbsp; &nbsp; &nbsp; &nbsp;</p>
    <p>
        <img src="/media/blue-baloons.jpeg"></p><p>Photo by Sofiya Levchenko on
        <a href="https://unsplash.com/photos/Meeycb5dpXs">Unsplash</a>
    </p>`;

export const DEFAULT_LETTER_CONTENT = EXAMPLE_CONGRATS_ALICE;
