import {createBrowserHistory} from 'history';
import {applyMiddleware, compose, createStore} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import logger from "redux-logger";

import {IS_DEBUG} from '../conf';

import {createRootReducer} from './reducers'
import DevTools from "../components/DevTools";

export const history = createBrowserHistory();
export type History = typeof history;

const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(preloadedState?: any) {
    const enhancer = IS_DEBUG ?
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history),
            ),
            applyMiddleware(thunkMiddleware),
            applyMiddleware(logger),
            DevTools.instrument()
        ) :
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history),
            ),
            applyMiddleware(thunkMiddleware)
        );

    const store = createStore(
        createRootReducer(history),
        preloadedState,
        enhancer
    );

    // Hot reloading
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createRootReducer(history));
        });
    }

    return store
}
