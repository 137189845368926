import { combineReducers } from 'redux';
import {connectRouter, RouterState} from 'connected-react-router';
import { History } from './configureStore';
import {
    OPEN_ENVELOPE,
    CLOSE_ENVELOPE,
    EDIT_LETTER,
    GIFT_FETCHED,
    FAILED_FETCH_GIFT,
    GIFT_IS_FETCHING,
    GIFT_IS_SAVING,
    GIFT_WAS_SAVED,
    GIFT_WAS_MODIFIED,
    FAILED_SAVE_GIFT,
    CREATE_GIFT,
    GO_HOME,
    CLEAR_REDIRECT,
    EDIT_DETAILS,
    SAVE_GIFT_CONTENT,
    SAVE_GIFT_TITLE,
    PUBLISH_GIFT,
    PUBLISH_GIFT_CANCEL,
    PUBLISH_GIFT_START, CREATE_GIFT_ERROR,
} from "./actions";

interface AppTypes {
    type: string
}

const newGiftState = {
    needOpenEnvelope: true,
    needEditLetter: true,
    needEditDetails: false,
    needPublishGift: false,
    fetchedGiftId: '',
    giftTitle: '',
    giftPublished: false,
    giftFetchingError: null,
    isGiftFetching: false,
    needSaveGift: false,
    isGiftSaving: false,
    giftSavingErrors: '',
    giftWasSaved: true,
    isGiftPublishing: false,
    giftCreationError: null
};

export const initialState = {
    ...newGiftState,
    needOpenEnvelope: false,
    needEditLetter: false,
    redirectTo: '',
};

function appReducers(
    state = initialState,
    action: any
): typeof initialState {
    switch (action.type) {
        case OPEN_ENVELOPE:
            return {...state, needOpenEnvelope: true};
        case CLOSE_ENVELOPE:
            return {
                ...state,
                needOpenEnvelope: false,
                needEditLetter: false,
            };
        case EDIT_LETTER:
            return {
                ...state,
                needEditLetter: true,
                needOpenEnvelope: true,
            };
        case GIFT_FETCHED:
            return {
                ...state,
                fetchedGiftId: action.fetchedGiftId,
                giftTitle: action.giftTitle,
                giftPublished: action.giftPublished,
                giftFetchingError: null,
                isGiftFetching: false,
                giftWasSaved: action.hasContent,
            };
        case GIFT_IS_FETCHING:
            return {
                ...state,
                fetchedGiftId: action.fetchedGiftId,
                giftTitle: '',
                giftFetchingError: null,
                isGiftFetching: true
            };
        case FAILED_FETCH_GIFT:
            return {
                ...state,
                fetchedGiftId: action.fetchedGiftId,
                giftTitle: '',
                giftFetchingError: action.giftFetchingError,
                isGiftFetching: false
            };
        case SAVE_GIFT_CONTENT:
            return {
                ...state,
                needSaveGift: true,
                needEditLetter: false,
                needEditDetails: false,
                giftSavingErrors: '',
            };
        case SAVE_GIFT_TITLE:
            const _giftTitle = action.needSave ? action.giftTitle : state.giftTitle;
            return {
                ...state,
                needSaveGift: action.needSave,
                giftTitle: _giftTitle,
                needEditLetter: false,
                needEditDetails: false,
                giftSavingErrors: '',
            };
        case GIFT_IS_SAVING:
            return {
                ...state,
                needSaveGift: false,
                needEditDetails: false,
                isGiftSaving: true,
                giftSavingErrors: '',
                giftWasSaved: false,
            };
        case GIFT_WAS_SAVED:
            return {
                ...state,
                fetchedGiftId: action.fetchedGiftId,
                giftTitle: action.giftTitle,
                giftPublished: action.giftPublished,
                isGiftSaving: false,
                giftSavingErrors: '',
                giftWasSaved: true,
                isGiftPublishing: false,
            };
        case GIFT_WAS_MODIFIED:
            return {
                ...state,
                giftWasSaved: false,
            };
        case FAILED_SAVE_GIFT:
            return {
                ...state,
                isGiftSaving: false,
                giftSavingErrors: action.giftSavingErrors,
                giftWasSaved: false,
                isGiftPublishing: false,
            };
        case CREATE_GIFT:
            return {
                ...newGiftState,
                giftCreationError: null,
                redirectTo: action.redirectTo
            };
        case CREATE_GIFT_ERROR:
            return {
                ...newGiftState,
                redirectTo: '',
                giftCreationError: action.error,
            };
        case GO_HOME:
            return {
                ...state,
                // giftWasSaved: state.giftWasSaved,
                redirectTo: '/'
            };
        case CLEAR_REDIRECT:
            return {
                ...state,
                redirectTo: ''
            };
        case EDIT_DETAILS:
            return {
                ...state,
                needEditDetails: true
            };
        case PUBLISH_GIFT:
            return {
                ...state,
                needPublishGift: true
            };
        case PUBLISH_GIFT_CANCEL:
            return {
                ...state,
                needPublishGift: false
            };
        case PUBLISH_GIFT_START:
            return {
                ...state,
                needPublishGift: false,
                isGiftPublishing: true,
            };
        default:
            return {...state};
    }
}

export const createRootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    app: appReducers
});

export interface RootState {
    router: RouterState,
    app: typeof initialState
}
