import log from "./log";
import {STATIC_PAGES} from "./conf";

export const trimTrailingSlashes = (str: string) => {
    return str.replace(/\/+$/g, '');
};

export const isHomePage = (path: string) : boolean => {
    return path === '/';
};

export const isStaticPage = (path: string) : boolean => {
    return STATIC_PAGES.includes(path);
};

export const textToHtml = (text: string) : string => {
    const html = text
        .replace(/\s/g, '&nbsp;')
        .replace(/\n/g, '<br/>');
    return html
};

export const htmlToText = (html: string) : string => {
    const text = html
        .replace(/&nbsp;/g, ' ')
        .replace(/<br\/>/g, '/n');
    return text
};

export const isShareAvailable = () : boolean => {
    return Boolean(navigator && navigator.share);
};

export const navigatorShare = async (shareData: ShareData) => {

    log.debug('navigatorShare: ', navigator, navigator.share);

    try {
        if(navigator && navigator.share) {
            await navigator.share(shareData);
        }
    } catch(e) {
        log.error('Failed to invoke navigator.share with: ', e);
    }
};

export const getNavigatorLanguage = () => {
    if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
    } else {
        return (navigator as any).userLanguage
            || navigator.language
            || (navigator as any).browserLanguage
            || 'en';
    }
};
