import React, {FC} from "react";
import {withStyles} from "@material-ui/core/styles";
import {Backdrop, CircularProgress} from "@material-ui/core";

const ProgressBackdrop = withStyles({
    root: {
        zIndex: 1,
    }
})(Backdrop);

type ProgressProps = {
    show: boolean
}

const Progress: FC<ProgressProps> = ({show = true}) => {
    return (
        <ProgressBackdrop open={show} >
            <CircularProgress color="inherit" />
        </ProgressBackdrop>
    )
};

export default Progress;
