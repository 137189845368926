import * as logging from 'loglevel';
import {IS_DEBUG} from "./conf";

const log = logging.getLogger('main');
if(IS_DEBUG) {
    log.enableAll();
}
else {
    log.disableAll();
}

export default log;

