import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {AppContainer} from 'react-hot-loader';
import {SnackbarProvider} from 'notistack';

import {IS_DEBUG} from './conf';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import {configureStore, history} from './store/configureStore';
import DevTools from './components/DevTools';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const ghspa = require('ghspa');

const store = configureStore();

ReactDOM.render(
    <AppContainer>
        <Provider store={store}>
            <SnackbarProvider>
                <App history={history}/>
            </SnackbarProvider>
            {IS_DEBUG && <DevTools/>}
        </Provider>
    </AppContainer>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
