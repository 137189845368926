import React, {FC, useRef, useState, useEffect} from "react";
import {connect, ConnectedProps} from 'react-redux'
import {createMatchSelector} from "connected-react-router";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withSnackbar } from 'notistack';

import DetailsDialog from "./DetailsDialog";
import dompurify from 'dompurify';
import {mdiEmailVariant} from '@mdi/js';

import {
    openEnvelopeAction,
    closeEnvelopeAction,
    GIFT_FETCHED,
    FAILED_FETCH_GIFT,
    GIFT_IS_FETCHING,
    GIFT_IS_SAVING,
    GIFT_WAS_SAVED,
    GIFT_WAS_MODIFIED,
    FAILED_SAVE_GIFT
} from '../store/actions';
import {RootState} from "../store/reducers";
import {requests} from "../api";
import {DEFAULT_LETTER_CONTENT} from '../templateContent';
import log from '../log';
import {AlloyEditor, EDITOR_CONFIG} from "../editorConfig";
import 'alloyeditor/dist/alloy-editor/assets/alloy-editor-ocean-min.css'
import 'react-perfect-scrollbar/dist/css/styles.css';

import '../style/envelope.scss';
import {Paper, Button} from "@material-ui/core";
import PublishDialog from "./PublishDialog";
import Icon from "@mdi/react";
import {inspect} from "util";

const giftIdExtractor = (state: RootState) => {
    const match = createMatchSelector("/:giftId")(state);
    return match && match.params && (match.params as any).giftId ?
        (match.params as any).giftId :
        null;
};

const mapStateToProps = (state: RootState) => ({
    giftId: giftIdExtractor(state),
    ...state.app
});

const mapDispatchToProps = (dispatch: any) => ({
    openEnvelope: () =>
        dispatch(openEnvelopeAction()),
    closeEnvelope: () =>
        dispatch(closeEnvelopeAction()),
    giftFetched: (giftId: string, giftTitle: string, giftPublished: boolean, hasContent: boolean) =>
        dispatch({type: GIFT_FETCHED, fetchedGiftId: giftId, giftTitle, giftPublished, hasContent}),
    giftIsFetching: (giftId: string) =>
        dispatch({type: GIFT_IS_FETCHING, fetchedGiftId: giftId}),
    failedToFetchGift: (giftId: string, error: string) =>
        dispatch({type: FAILED_FETCH_GIFT, fetchedGiftId: giftId, giftFetchingError: error}),
    actionGiftSaved: (giftId: string, giftTitle: string, giftPublished: boolean) =>
        dispatch({type: GIFT_WAS_SAVED, fetchedGiftId: giftId, giftTitle, giftPublished}),
    actionGiftIsSaving: () =>
        dispatch({type: GIFT_IS_SAVING}),
    actionGiftWasModified: () =>
        dispatch({type: GIFT_WAS_MODIFIED}),
    actionFailedSaveGift: (error: string) =>
        dispatch({type: FAILED_SAVE_GIFT, giftSavingErrors: error}),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type GiftBoxProps = ConnectedProps<typeof connector> & {};

const GiftBox: FC<GiftBoxProps> = (props: GiftBoxProps) => {
    const [isEnvelopeOpen, setIsEnvelopeOpen] = useState(false);
    const [content, setContent] = useState(DEFAULT_LETTER_CONTENT);

    const [alloyEditor, setAlloyEditor] = useState<any>(null);
    const letter = useRef<HTMLDivElement>(null);
    const flip = useRef<HTMLDivElement>(null);
    const contentData = useRef<HTMLDivElement>(null);

    const toggleEnvelope = () => {
        if (isEnvelopeOpen) {
            props.closeEnvelope();
        } else {
            props.openEnvelope();
        }
    };

    const openEnvelope = () => {
        if (isEnvelopeOpen) {
            return;
        }
        setIsEnvelopeOpen(true);
        if (letter.current !== null && flip.current !== null) {
            letter.current.style.display = 'block';
            flip.current.classList.add('envelope-open');
            flip.current.classList.remove('envelope-close');
            setTimeout(() => {
                if (letter.current !== null && flip.current !== null) {
                    // flip.current.style.zIndex = '10';
                    flip.current.style.zIndex = '-50';
                    letter.current.classList.add('letter-moved-up');
                    letter.current.classList.remove('letter-moved-down');

                    setTimeout(() => {
                        if (letter.current !== null && flip.current !== null) {
                            // letter.current.style.zIndex = '50';
                            letter.current.style.zIndex = '0';
                            letter.current.classList.add('letter-moved-down');
                            letter.current.classList.remove('letter-moved-up');
                        }
                    }, 400);
                }
            }, 400);
        }
    };

    const closeEnvelope = () => {
        if (!isEnvelopeOpen) {
            return;
        }
        setIsEnvelopeOpen(false);
        if (letter.current !== null && flip.current !== null) {
            letter.current.classList.add('letter-moved-up');
            letter.current.classList.remove('letter-moved-down');

            setTimeout(() => {
                if (letter.current !== null && flip.current !== null) {
                    // letter.current.style.zIndex = '20';
                    letter.current.style.zIndex = '-40';
                    letter.current.classList.add('letter-moved-down');
                    letter.current.classList.remove('letter-moved-up');

                    setTimeout(() => {
                        if (letter.current !== null && flip.current !== null) {
                            // flip.current.style.zIndex = '40';
                            flip.current.style.zIndex = '0';
                            flip.current.classList.add('envelope-close');
                            flip.current.classList.remove('envelope-open');
                        }
                    }, 400);
                }
            }, 400);
        }
    };

    const enableLetterEditor = () => {
        if (alloyEditor == null) {
            const ae = AlloyEditor.editable('letter-content-view', EDITOR_CONFIG);

            setAlloyEditor(ae);
            const editor = ae.get('nativeEditor');
            editor.on('instanceReady', (event: any) => {
                if (event.editor.focus)
                    event.editor.focus();
            });
            props.actionGiftWasModified();
        }
    };

    const disableLetterEditor = () => {
        if (alloyEditor !== null) {
            const editor = alloyEditor.get('nativeEditor');
            editor.on('instanceDestroyed', (event: any) => {
                editor.replace('letter-content-view');
            });

            editor.removeAllListeners();
            alloyEditor.destroy(true);
            setAlloyEditor(null);
        }
    };

    const {
        isGiftFetching, giftFetchingError, fetchedGiftId, giftId, giftIsFetching,
        giftFetched, failedToFetchGift
    } = props;
    useEffect(() => {
        const fetchGift = async () => {
            try {
                const data = await requests.get(`/gift/${giftId}`);
                const giftContent = data.body.giftData;
                if (giftContent) {
                    setContent(giftContent);
                }
                giftFetched(data.body.giftId, data.body.giftTitle, data.body.giftPublished, !!giftContent);
            } catch (e) {
                log.error(`Failed to receive gift ${giftId} with error: ${e}`);
                failedToFetchGift(giftId, e);
            }
        };

        if (fetchedGiftId !== giftId && !isGiftFetching && !giftFetchingError) {
            giftIsFetching(giftId);
            fetchGift();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGiftFetching, giftFetchingError, giftId, giftIsFetching,
        giftFetched, failedToFetchGift]);

    useEffect(() => {
        const updateTitle = () => {
            const basicTitle = 'Virtual Gift Online';
            document.title = props.giftTitle
                ? `${props.giftTitle} — ${basicTitle}`
                : basicTitle;
            log.debug(`Updated title: ${document.title}, props.giftTitle: ${props.giftTitle}`);
        };
        updateTitle();
    }, [props.giftTitle]);

    useEffect(() => {
        const saveGift = async () => {
            try {
                let _contentData = null;
                if (alloyEditor) {
                    const editor = alloyEditor.get('nativeEditor');
                    _contentData = editor.getData(false);
                }
                if (!_contentData && contentData.current) {
                    _contentData = contentData.current.innerHTML;
                }
                await requests.put(`/gift/${giftId}/update/`, {
                    giftData: _contentData,
                    giftTitle: props.giftTitle,
                    giftPublished: props.isGiftPublishing
                });
                const text: string = `Virtual Gift has been ${props.isGiftPublishing ? 'published' : 'saved'}`;
                props.actionGiftSaved(giftId, props.giftTitle, props.isGiftPublishing);
                (props as any).enqueueSnackbar(text, {
                    variant: 'success',
                    vertical: 'bottom',
                    horizontal: 'center',
                });
            } catch (e) {
                const text: string = `Failed to save gift ${giftId} with error: ${e}`;
                log.error(text);
                props.actionFailedSaveGift(e);
                (props as any).enqueueSnackbar(text, {
                    variant: 'error',
                    vertical: 'bottom',
                    horizontal: 'center',
                });
            }
        };

        if (giftId && props.needSaveGift && !props.isGiftSaving && !props.giftSavingErrors) {
            props.actionGiftIsSaving();
            saveGift();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.needSaveGift]);

    useEffect(() => {
        if (props.needOpenEnvelope) {
            openEnvelope()
        } else {
            closeEnvelope()
        }
    }, [props, openEnvelope, closeEnvelope]);

    useEffect(() => {
        if (props.needEditLetter) {
            enableLetterEditor();
        } else {
            disableLetterEditor();
        }
    }, [props, enableLetterEditor, disableLetterEditor]);

    const renderTitle = () => (
        <Paper className="envelope-body-front-title" elevation={5} >
            <p>{props.giftTitle}</p>
        </Paper>
    );

    const renderGiftBox = () => (
        <div className="gift-box">
            <div className={'container'}>
                <div className="envelope">
                    <div className="envelope-body-bg"/>
                    <div className="envelope-body-front notranslate">
                        { props.giftTitle && renderTitle() }
                    </div>
                    <div className="envelope-flip" ref={flip} onClick={toggleEnvelope}>
                        <div className="envelope-flip-face envelope-flip-face-back">
                            <Button className="cover-button">
                                <Icon path={mdiEmailVariant}
                                      size={2}
                                      horizontal
                                      vertical
                                      rotate={180}
                                />
                            </Button>
                        </div>
                        <div className="envelope-flip-face envelope-flip-face-front">
                        </div>
                    </div>
                    <div className="envelope-letter" ref={letter} >
                        <PerfectScrollbar>
                            {/*<PerfectScrollbar style={{paddingLeft:20, marginLeft:20}}>*/}
                            <div id="letter-content-view" className="envelope-text notranslate"
                                 ref={contentData}
                                 dangerouslySetInnerHTML={{__html: dompurify.sanitize(content,{
                                         ADD_TAGS: ['iframe']
                                     })}}>
                            </div>
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>

            <DetailsDialog/>
            <PublishDialog/>
        </div>
    );

    const renderGiftLoadError = () => (
        <div className="gift-box">
            <div className={'container'}>
                <Paper className="gift-load-error">
                    <h2>Virtual Gift was failed to load</h2>
                    <p><strong>{String(props.giftFetchingError)}</strong></p>
                    <p>You can <a href="/">create another gift</a> or write us about the accident:
                        <a href="mailto:info@vgift.us">info@vgift.us</a>.</p>
                    <p>Full text of the error:</p>
                    <p className="gift-full-error notranslate"> {inspect(props.giftFetchingError)}</p>
                </Paper>
            </div>
        </div>
    );

    return (
        props.giftFetchingError ? renderGiftLoadError() : renderGiftBox()
    );
};

export default withSnackbar(connector(GiftBox));

/*
style={{
    display: props.needOpenEnvelope ? 'block' : 'none'
}}*/
