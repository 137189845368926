import React, {FC} from 'react';
import {connect, ConnectedProps} from "react-redux";
import {
    DialogTitle,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
} from "@material-ui/core";

import {RootState} from "../store/reducers";
import {PUBLISH_GIFT_CANCEL, publishGift} from "../store/actions";

const mapStateToProps = (state: RootState) => ({
    needPublishGift: state.app.needPublishGift
});

const mapDispatchToProps = (dispatch: any) => ({
    actionPublishGift: () =>
        dispatch(publishGift()),
    actionPublishCancel: () =>
        dispatch({type: PUBLISH_GIFT_CANCEL}),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PublishDialogProps = ConnectedProps<typeof connector> & {};

const PublishDialog: FC<PublishDialogProps> = (props: PublishDialogProps) => {
    const handleCancel = () => {
        props.actionPublishCancel();
    };

    const handlePublish = () => {
        props.actionPublishGift();
    };

    return (
        <Dialog open={props.needPublishGift} onClose={handleCancel}
                aria-labelledby="form-gift-publish-dialog">
            <DialogTitle id="form-gift-publish-dialog">Publish Virtual Gift</DialogTitle>
            <DialogContent dividers>
                <h2>Now you are about to publish your Virtual Gift.</h2>

                <p>It is an <strong>irreversible action</strong>.
                    Once you have done it, you can't undo it back.</p>
                <p>After publishing the gift will not be able for editing by someone, including you!</p>
                <p>Publish it if you already made all the changes you want an ready to share the gift.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handlePublish} color="primary">
                    Publish
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connector(PublishDialog);
